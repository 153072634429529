import styled from 'styled-components';

export const ServicesContainer = styled.section`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.81em;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export const Service = styled.section<{ backgroundImg: string }>`
  width: 30%;
  height: 30em;
  aspect-ratio: 1/1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  transition: all 0.3s ease;
  text-align: center;
  padding: 0.5em;
  &:hover {
    scale: 1.01;
  }
  @media (max-width: 1200px) {
    width: 90%;
    aspect-ratio: unset;
    padding: 5%;
  }
`;

export const ServicesTitle = styled.h1`
  font-size: 1.8em;
`;

export const ConcessionsContainer = styled.section`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5%;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export const Concession = styled.section`
  width: 10em;
  aspect-ratio: 1/1;
  background: #382336;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em auto;
  align-items: center;
  padding: 1.5em 2em;
  border-radius: 15px;
  text-align: center;
  h2 {
    font-size: 1.4em;
  }
  svg {
    font-size: 5em;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    width: 100%;
    aspect-ratio: unset;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FreeMsg = styled.section`
  text-align: center;
  max-width: 90%;
  p {
    margin: 0;
  }
  p:first-child {
    font-size: 1.2em;
    margin-top: 1em;
  }
`;

export const OpeningTimesContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 5em;
  justify-content: center;
`;

export const AvailabilityTitle = styled.h1`
  margin-bottom: 4em;
`;

export const OpeningTime = styled.div`
  width: 10em;
  aspect-ratio: 1/1;
  background: #382336;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em auto;
  align-items: center;
  padding: 1.5em 2em;
  border-radius: 15px;
  text-align: center;
  position: relative;
  min-height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    position: absolute;
    width: 1em;
    top: -65px;
    left: -45px;
    font-size: 19.5em;
    height: 135%;
  }
  h2 {
    font-size: 1.5em;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;

export const OpeningSpan = styled.span`
  margin-top: 0.5em;
  a {
    color: #ffffff;
  }
`;
