import React, { ReactElement } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import {
  StyledAccordion,
  Questions,
  StyledAccordionDetails,
  FAQTitle,
} from '../Contact/FAQStyles';

const questions = [
  {
    summary: 'How do I know if you are the right counsellor for me?',
    description: (
      <>
        <p>The quick answer is, you don&apos;t.</p>
        <p>
          I do believe that the therapeutic relationship between counsellor and
          client is the foundation in which we will build on. This is why I
          offer a no pressure, no obligation 30mins free consultation in order
          for you to decide if you feel that you would like to work with me.
          This can be done over the phone or via zoom.
        </p>
      </>
    ),
  },
  {
    summary: 'How many sessions do I need?',
    description: (
      <>
        <p>
          This varies from client to client. I offer long term as well as short
          term counselling.
        </p>
        <p>
          I do undertake regular reviews, which can determine by mutual
          agreement if further sessions are needed or not.
        </p>
      </>
    ),
  },
  {
    summary: "What if I can't make a session?",
    description: (
      <>
        <p>
          If it is not possible for you to attend a session, please let me know
          as soon as you can via text, email or voice message.
        </p>
        <p>
          Cancellations will be charged at the session rate if notified less
          than 24hrs from the time of the appointment.
        </p>
      </>
    ),
  },
];

const FAQs = (): ReactElement => (
  <PageWrapper title={PageTitles.FAQs}>
    <>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <Questions>
        {questions.map((question) => (
          <StyledAccordion className="faq">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{question.summary}</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              <Typography>{question.description}</Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </Questions>
    </>
  </PageWrapper>
);
export default FAQs;
