import { NavigateFunction } from 'react-router';
import emailjs from '@emailjs/browser';

interface SendMailType {
  name: string;
  email: string;
  phoneNo?: string;
  message: string;
  navigate: NavigateFunction;
  setShowErrorSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const sendMail = ({
  name,
  email,
  phoneNo,
  message,
  navigate,
  setShowErrorSnackbar,
  setLoading,
}: SendMailType): void => {
  // nathan@serverlini.com emailjs account
  emailjs
    .send(
      'IONOS',
      'TemplateToUseForLinda',
      {
        name,
        email,
        phoneNo: phoneNo || 'This person left no phone number',
        message,
      },
      'DFrSHSyypn6WPFevg'
    )
    .then(
      (result) => {
        if (result.status === 200) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          navigate('/contact-success');
        }
      },
      () => {
        setShowErrorSnackbar(true);
        setLoading(false);
      }
    );
};

export default sendMail;
