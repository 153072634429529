import styled from 'styled-components';

export const Container = styled.section`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50em;
`;

export const Test = styled.div``;
