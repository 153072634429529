import styled from 'styled-components';

export const AboutIntroSection = styled.div`
  display: flex;
  gap: 4em;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    gap: 0;
    align-items: center;
    flex-direction: column;
  }
`;

export const LindaImg = styled.img`
  border-radius: 50%;
  max-height: 26rem;
  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const BACPImg = styled.img`
  height: 5em;
  border-radius: 10px;
  border: solid 5px #5d3659;
  padding: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px #ffffff;
  @media (max-width: 768px) {
    width: 30%;
    height: unset;
  }
`;

export const BACPContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: 940px) {
    gap: 0;
    justify-content: center;
    flex-direction: column;
  }
`;
