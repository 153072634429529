import styled from 'styled-components';
import BannerBackground from '../../../assets/HomepageBanner.jpg';

export const BannerWrapper = styled.section`
  position: relative;
  width: 96%;
  height: 38em;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${BannerBackground});
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  padding: 0 2%;
  @media (max-width: 768px) {
    height: 23em;
  }
  p,
  q {
    font-size: 2em;
    text-align: justify;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
  p {
    margin-top: 0;
    text-align: right;
  }
`;

export const test = styled.section``;
