import React, { ReactElement } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CopyrightSection, FooterContent, FooterWrapper } from './styles';
import { BACPImg } from '../../pages/About/styles';
import BACP from '../../assets/BACPLogo.png';

const Footer = (): ReactElement => (
  <FooterWrapper>
    <FooterContent>
      <CopyrightSection>
        <p>&copy; 2022-{new Date().getFullYear()} by Linda Ashcroft</p>
        <section>
          <LocationOnIcon /> <p>Wigan</p>
        </section>
        <section>
          <PhoneIcon /> <a href="tel:07719968898">07719968898</a>
        </section>
        <section>
          <EmailIcon />{' '}
          <a href="mailto:linda@ashcroftcounselling.co.uk">
            linda@ashcroftcounselling.co.uk
          </a>
        </section>
      </CopyrightSection>
      <section>
        <BACPImg
          src={BACP}
          alt="British Association for Counselling and Psychotherapy"
        />
      </section>
    </FooterContent>
  </FooterWrapper>
);

export default Footer;
