import styled from 'styled-components';

export const PrivacyPolicyTitle = styled.h1`
  font-size: 1.8em;
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  h2 {
    text-align: center;
  }
  a {
    color: #ffffff;
  }
`;
