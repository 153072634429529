import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { TextField, TextareaAutosize } from '@mui/material';

export const ContactContainer = styled.section`
  width: 90%;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
`;
export const Name = styled.section`
  width: 48%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PhoneNo = styled.section`
  width: 48%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Email = styled.section`
  width: 100%;
`;
export const Message = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
`;

export const StyledInput = muiStyled(TextField)({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
    transition: 'all ease 0.3s',
    fontFamily: 'Kanit',
  },
  '& .MuiOutlinedInput-root': {
    wudth: '100%',
    color: '#ffffff',
    '& fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'ffffff40',
    },
  },
});

export const MessageHelperText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  padding-left: 1em;
  font-size: 0.75rem;
  font-family: 'Roboto';
  font-weight: 400;
  letter-spacing: 0.03333em;
`;

export const StyledTextarea = muiStyled(TextareaAutosize)(({ theme }) => ({
  background: 'transparent',
  borderColor: '#ffffff',
  color: '#ffffff',
  fontSize: '1em',
  padding: '2%',
  fontFamily: 'Kanit',
  width: '96%',
  minHeight: '3em',
  resize: 'vertical',
  '&::placeholder': {
    color: '#ffffff',
  },
  '&.error': {
    borderColor: theme.palette.error.main,
    '&::placeholder': {
      color: theme.palette.error.main,
    },
  },
}));

export const ContactTitle = styled.h1`
  font-size: 1.8em;
  margin-bottom: 0;
`;

export const ContactIntro = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  width: 90%;
  a {
    color: #ffffff;
  }
  p {
    margin: 0;
  }
`;
