/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';

interface MobileNavContextType {
  mobileNavOpen: boolean;
  slideOut: boolean;
  setMobileNavOpen: (state: boolean) => void;
  setSlideOut: (state: boolean) => void;
}

export const MobileNavContext = createContext<MobileNavContextType>({
  mobileNavOpen: false,
  slideOut: false,
  setMobileNavOpen: () => {},
  setSlideOut: () => {},
});
