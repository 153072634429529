import styled from 'styled-components';

export const ExperienceContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding-bottom: 1em;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`;

export const ExperienceBox = styled.section`
  background: #644f62;
  transition: all 0.4s ease;
  padding: 1em;
  border: solid 0.2em #382336;
  min-width: 25%;
  min-height: 3em;
  text-align: center;
  flex: 1;
  transform-style: preserve-3d;
  perspective: 1000px;
  cursor: pointer;
  &:hover {
    scale: 1.03;
  }
  p {
    font-size: 1em;
  }
  @media (max-width: 768px) {
    min-width: 90%;
    min-height: 5em;
  }
`;

export const Description = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #644f62;
  top: 0;
  left: 0;
  overflow-y: auto;
  transform: rotateY(180deg) translateZ(50px);
  p {
    margin: 0.5rem 0;
  }
  li {
    text-align: left;
    margin: 0.4em 0;
  }
`;

export const Title = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #644f62;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
