enum PageTitles {
  Homepage = 'Home | Ashcroft Counselling',
  About = 'About | Ashcroft Counselling',
  ContactFAQs = 'FAQs & Contact | Ashcroft Counselling',
  FAQs = 'FAQs | Ashcroft Counselling',
  ServicesAndFees = 'Services And Fees | Ashcroft Counselling',
  PageNotfound = 'Page Not Found | Ashcroft Counselling',
  MailSuccess = 'Success | Ashcroft Counselling',
  MailError = 'Error | Ashcroft Counselling',
}
export default PageTitles;
