import React, { ReactElement } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import { Container } from './styles';

const MailSuccess = (): ReactElement => (
  <PageWrapper title={PageTitles.MailSuccess}>
    <Container>
      <h1>Form successfully sent</h1>
      <p>I will contact you as soon as possible regarding your message</p>
    </Container>
  </PageWrapper>
);
export default MailSuccess;
