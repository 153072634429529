import React, { ReactElement, useState } from 'react';
import {
  Description,
  ExperienceBox,
  Title,
} from '../../pages/About/Experience/styles';

interface FlipBoxProps {
  title: string;
  description: string | ReactElement;
}

const FlipBox = ({ title, description }: FlipBoxProps): ReactElement => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped((prev) => !prev);
  };

  return (
    <ExperienceBox
      onClick={handleClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') handleClick();
      }}
      style={{ transform: isFlipped ? 'rotate3d(0, 1, 0, 180deg)' : 'none' }}
      tabIndex={0}
    >
      <Title>
        <p>{title}</p>
      </Title>
      <Description>
        {typeof description === 'string' ? <p>{description}</p> : description}
      </Description>
    </ExperienceBox>
  );
};

export default FlipBox;
