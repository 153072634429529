import React, { ReactElement } from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import { Qualification, QualificationsContainer } from './styles';

const quals = [
  'NCFE Level 1 Award in interpersonal skills.',
  'ABC Award Level 2 Award in Counselling Concepts.',
  'ABC Level 3 Certificate in Counselling Skills.',
  'SEG Level 4 Diploma in Therapeutic Counselling.',
];

const Qualifications = (): ReactElement => (
  <>
    <h2>Qualifications</h2>
    <QualificationsContainer>
      {quals.map((qual) => (
        <Qualification>
          <GradeIcon />
          <p>{qual}</p>
        </Qualification>
      ))}
    </QualificationsContainer>
  </>
);
export default Qualifications;
