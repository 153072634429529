import {
  Button,
  useTheme,
  Snackbar,
  Alert,
  AlertTitle,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import sendMail from './ContactUtils';
import {
  FAQTitle,
  Questions,
  StyledAccordion,
  StyledAccordionDetails,
} from './FAQStyles';
import {
  ContactContainer,
  ContactTitle,
  Email,
  Message,
  MessageHelperText,
  Name,
  PhoneNo,
  StyledForm,
  StyledInput,
  StyledTextarea,
  ContactIntro,
} from './styles';
import { ErrorMessages, EMAIL_REGEX } from './types';
import { PrivacyPolicy, PrivacyPolicyTitle } from './PrivacyPolicyStyles';
import PolicyInfo from '../../components/PolicyInfo/PolicyInfo';

const questions = [
  {
    summary: 'How do I know if you are the right counsellor for me?',
    description: (
      <>
        <p>The quick answer is, you don&apos;t.</p>
        <p>
          I do believe that the therapeutic relationship between counsellor and
          client is the foundation in which we will build on. This is why I
          offer a no pressure, no obligation, 20 minute free consultation in
          order for you to decide if you feel that you would like to work with
          me. This can be done over the phone or via zoom.
        </p>
      </>
    ),
  },
  {
    summary: 'How many sessions do I need?',
    description: (
      <>
        <p>
          This varies from client to client. I offer long term as well as short
          term counselling.
        </p>
        <p>
          I do undertake regular reviews, which can determine by mutual
          agreement if further sessions are needed or not.
        </p>
      </>
    ),
  },
  {
    summary: "What if I can't make a session?",
    description: (
      <>
        <p>
          If it is not possible for you to attend a session, please let me know
          as soon as you can via text, email or voice message.
        </p>
        <p>
          Cancellations will be charged at the session rate if notified less
          than 24hrs from the time of the appointment.
        </p>
      </>
    ),
  },
];

const Contact = (): ReactElement => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [nameInvalid, setNameInvalid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoValid, setPhoneNoValid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [message, setMessage] = useState('');
  const [messageValid, setMessageValid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const areErrors =
    nameInvalid.invalid ||
    emailInvalid.invalid ||
    phoneNoValid.invalid ||
    messageValid.invalid;
  return (
    <PageWrapper title={PageTitles.ContactFAQs}>
      <>
        <FAQTitle>Frequently Asked Questions</FAQTitle>
        <Questions>
          {questions.map((question) => (
            <StyledAccordion className="faq">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{question.summary}</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                <Typography>{question.description}</Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        </Questions>
        <PrivacyPolicyTitle>Privacy Policy</PrivacyPolicyTitle>
        <PrivacyPolicy>
          <StyledAccordion className="faq">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Click here to view</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                <PolicyInfo />
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
        </PrivacyPolicy>
        <ContactTitle>Contact</ContactTitle>
        <ContactIntro>
          <p>
            To get in contact with me, please fill in the contact form below
          </p>
          <p>
            Alternatively, you can contact me via phone at{' '}
            <a href="tel:07719968898">07719968898</a>
          </p>
          <p>
            You can also contact me via email here:{' '}
            <a href="mailto:linda@ashcroftcounselling.co.uk">
              linda@ashcroftcounselling.co.uk
            </a>
          </p>
        </ContactIntro>
        <ContactContainer>
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              if (name && email && message) {
                setLoading(true);
                // submit email but pass in checks in case it breaks
                sendMail({
                  name,
                  email,
                  phoneNo,
                  message,
                  navigate,
                  setShowErrorSnackbar,
                  setLoading,
                });
              } else {
                if (!name) {
                  setNameInvalid({
                    invalid: true,
                    message: ErrorMessages.Required,
                  });
                }
                if (!email) {
                  setEmailInvalid({
                    invalid: true,
                    message: ErrorMessages.Required,
                  });
                } else {
                  // there could be an email so check it is a valid email!
                  const validEmail = EMAIL_REGEX.test(email);
                  if (!validEmail) {
                    setEmailInvalid({
                      invalid: true,
                      message: ErrorMessages.BadEmail,
                    });
                  }
                }
                if (!message) {
                  setMessageValid({
                    invalid: true,
                    message: ErrorMessages.Required,
                  });
                }
              }
            }}
          >
            <Name>
              <StyledInput
                label="Name *"
                variant="outlined"
                color="primary"
                id="name"
                value={name}
                onChange={(e) => {
                  setNameInvalid({
                    invalid: false,
                    message: null,
                  });
                  setName(e.target.value);
                }}
                error={nameInvalid.invalid}
                helperText={nameInvalid.message}
              />
            </Name>
            <PhoneNo>
              <StyledInput
                label="Phone number"
                type="tel"
                variant="outlined"
                color="primary"
                id="phoneNo"
                value={phoneNo}
                onChange={(e) => {
                  setPhoneNoValid({
                    invalid: false,
                    message: null,
                  });
                  setPhoneNo(e.target.value);
                }}
                error={phoneNoValid.invalid}
                helperText={phoneNoValid.message}
              />
            </PhoneNo>
            <Email>
              <StyledInput
                label="Email *"
                variant="outlined"
                color="primary"
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmailInvalid({
                    invalid: false,
                    message: null,
                  });
                  setEmail(e.target.value);
                }}
                error={emailInvalid.invalid}
                helperText={emailInvalid.message}
              />
            </Email>
            <Message>
              <StyledTextarea
                placeholder="What's on your mind?*"
                id="message"
                value={message}
                onChange={(e) => {
                  setMessageValid({
                    invalid: false,
                    message: null,
                  });
                  setMessage(e.target.value);
                }}
                className={messageValid.invalid ? 'error' : ''}
              />
              {messageValid.invalid && (
                <MessageHelperText color={theme.palette.error.main}>
                  {messageValid.message}
                </MessageHelperText>
              )}
            </Message>
            <Button
              disabled={areErrors}
              type="submit"
              color="primary"
              variant="outlined"
            >
              Send Message
            </Button>
          </StyledForm>
        </ContactContainer>
        {showErrorSnackbar && (
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={10000}
            onClose={() => {
              setShowErrorSnackbar(false);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="warning">
              <AlertTitle>The message failed to send!</AlertTitle>
              Unfortunately something went wrong, please contact me directly
              here:{' '}
              <a href="mailto:linda@ashcroftcounselling.co.uk">
                linda@ashcroftcounselling.co.uk
              </a>
            </Alert>
          </Snackbar>
        )}
        <Backdrop sx={{ color: '#ffffff', zIndex: '999' }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </PageWrapper>
  );
};
export default Contact;
