import React, { ReactElement, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  CloseMobile,
  HamburgerMenu,
  HeaderContent,
  HeaderWrapper,
  LogoContainer,
  MobileNav,
  MobileNavHeader,
  MobileNavLink,
  MobileNavLinks,
  Navigation,
  LogoImg,
} from './styles';
import { MobileNavContext } from '../../context/mobileNavContext';
import Logo from '../../assets/Logo-Transparent-With-Text.png';

const links = [
  {
    name: 'Homepage',
    link: '/',
    end: true,
  },
  {
    name: 'About',
    link: '/about',
    end: false,
  },
  {
    name: 'Services And Fees',
    link: '/services-and-fees',
    end: false,
  },
  {
    name: 'FAQs & Contact',
    link: '/faq-and-contact',
    end: false,
  },
];

const Header = (): ReactElement => {
  const { setMobileNavOpen, mobileNavOpen, slideOut, setSlideOut } =
    useContext(MobileNavContext);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderContent>
        <LogoContainer>
          <LogoImg
            src={Logo}
            alt="Ashcroft Counselling"
            onClick={() => {
              navigate('/');
            }}
          />
        </LogoContainer>
        {isMobile ? (
          <>
            <HamburgerMenu
              className="menu"
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  setMobileNavOpen(true);
                  document.body.style.position = 'fixed';
                }
              }}
              onClick={() => {
                setMobileNavOpen(true);
                document.body.style.position = 'fixed';
              }}
              tabIndex={0}
            />
            {mobileNavOpen && (
              <MobileNav
                className={slideOut ? 'slideOut' : ''}
                onAnimationEnd={() => {
                  if (slideOut) {
                    setMobileNavOpen(false);
                    setSlideOut(false);
                  }
                }}
              >
                <MobileNavHeader>
                  <CloseMobile
                    onClick={() => {
                      setSlideOut(true);
                      document.body.style.position = 'relative';
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ' ' || e.key === 'Enter') {
                        setSlideOut(true);
                        document.body.style.position = 'relative';
                      }
                    }}
                    tabIndex={0}
                  />
                </MobileNavHeader>
                <MobileNavLinks>
                  {links.map((link) => (
                    <MobileNavLink
                      onClick={() => {
                        setSlideOut(true);
                        document.body.style.position = 'relative';
                      }}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'asd'
                      }
                      to={link.link}
                      end={link.end}
                    >
                      {link.name}
                    </MobileNavLink>
                  ))}
                </MobileNavLinks>
              </MobileNav>
            )}
          </>
        ) : (
          <Navigation>
            {links.map((link) => (
              <NavLink to={link.link}>{link.name}</NavLink>
            ))}
          </Navigation>
        )}
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default Header;
