import Button from '@mui/material/Button';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import Banner from './Banner/Banner';
import { InnerWrapper, TextWrapper, ButtonWrapper } from './styles';

const Homepage = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <PageWrapper title={PageTitles.Homepage}>
      <InnerWrapper>
        <Banner />
        <TextWrapper>
          <h1>The answer lies within</h1>
          <p>
            Counselling provides a safe and secure space in which you can feel
            free to explore your thoughts and feelings. It is a caring and warm
            environment that welcomes you to be you. A place in which you will
            be listened to and heard without judgement or unwanted advice.
          </p>
          <p>
            It can be daunting for anyone to take the first step into therapy;
            however I will walk with you in your journey. Whatever you are
            struggling with can be talked through, processed and a way forward
            can be found.
          </p>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              navigate('/faq-and-contact');
            }}
          >
            Book a session
          </Button>
        </ButtonWrapper>
      </InnerWrapper>
    </PageWrapper>
  );
};
export default Homepage;
