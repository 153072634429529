import styled from 'styled-components';

export const QualificationsContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5%;
  justify-content: center;
  /* @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1em;
  } */
`;

export const Qualification = styled.section`
  width: 10em;
  aspect-ratio: 1 / 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em auto;
  padding: 1.5em 2em;
  border-radius: 15px;
  text-align: center;
  h2 {
    font-size: 1.4em;
  }
  svg {
    font-size: 5em;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    grid-template-rows: 5em 8em;
    aspect-ratio: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
