import React, { ReactElement } from 'react';

const PolicyInfo = (): ReactElement => (
  <>
    <h2>Ashcroft Counselling Privacy notice</h2>
    <p>
      <span>
        My contact details
        <br />
        Name: Linda Ashcroft
        <br />
        Phone Number: 07719968898
        <br />
        E-mail: linda@ashcroftcounselling.co.uk
        <br />
        Date: May 2023
      </span>
    </p>
    <p>
      In order to provide you with the best service possible I need to hold your
      personal contact details and records of your therapy sessions. This
      privacy notice tells you what I will do with your personal information
      from initial point of contact through to after therapy has ended. Your
      privacy is very important to me and you can be confident that your
      personal information will be kept safe and secure and will only be used
      for the purpose it was given to me. I adhere to current data protection
      legislation, including the General Data Protection Regulation
      (EU/2016/679) (the GDPR), the Data Protection Act 2018 and the Privacy and
      Electronic Communications (EC Directive) Regulations 2003. I also adhere
      to the ethical guidelines regarding protecting client privacy and
      confidentiality set by the British Association for Counselling and
      Psychotherapy (BACP).
    </p>
    <h2>My lawful basis for holding and using your personal information</h2>
    <p>
      The GDPR states that I must have a lawful basis for processing your
      personal data. There are different lawful basis depending on the stage at
      which I am processing your data. If you are currently having therapy or if
      you are in contact with me to consider therapy, I will process your
      personal data where it is necessary for the performance of our contract.
      Once therapy has ended with me I will use legitimate interest as my lawful
      basis for holding and using your personal information.
    </p>
    <p>
      The GDPR also makes sure that I look after any sensitive personal
      information that you may disclose to me appropriately. This type of
      information is called &apos;special category personal information&apos;.
      The lawful basis for me processing any special categories of personal
      information is that it is for provision of health treatment (in this case
      therapy sessions) and necessary for a contract with a health professional
      (in this case, a contract between me and you).
    </p>
    <h2>How I use your information</h2>
    <p>
      I will never use your personal data for any other purposes than the
      administration of the therapy service that I am providing to you, for
      example, to arrange, cancel and rearrange appointments. I will only retain
      your personal information for as long as is necessary. This is in line
      with guidance from the Information Commissioner&apos;s Office.
    </p>
    <p>
      On the initial contact I will collect some brief information to help me to
      process your enquiry and to book your first appointment.
    </p>
    <p>
      This will include your name, and/or email address, phone number. This
      information is requested so that you can be informed if I was unable to
      attend an appointment due to unforeseen circumstances. Alternatively, if
      an organisation such as your employer may send me your details when making
      a referral or a relative may give me your details when making an enquiry
      on your behalf. If an enquiry is made and you decide not to proceed, I
      will ensure that all of your personal data is deleted within one month. If
      you would like me to delete this information sooner, please let me know.
    </p>
    <p>
      Whilst accessing therapy with your written consent, I would only use your
      email address and telephone number to contact you regarding appointment
      times unless we have agreed that I will also use your email address to
      send you information that is relevant to your therapy sessions.
    </p>
    <p>
      Before your first appointment I will ask you to complete a referral form
      containing your name, address, date of birth, contact information and also
      contact information for your GP. The paper form is stored in a locked
      filing box that can only be accessed by me. If this form is completed
      electronically I store it on a USB memory stick which is password
      protected and stored in a locked box which only I have access to.
    </p>
    <p>
      Please note that in order to fulfil my duty of care towards you while also
      maintaining your confidentiality I will only contact your GP if it is
      necessary and should these circumstances arise, I would discuss this with
      you wherever possible before contacting your GP.
    </p>
    <p>
      Rest assured that what is said in our sessions will be kept confidential.
      I am a Registered Member of the British Association of Counselling &
      Psychotherapy (BACP) and I abide by their professional code of ethics.
      Confidentiality will only be broken if there are legal or ethical
      obligations to disclose, for example, if you disclose abuse/neglect of a
      child or vulnerable adult, or say something else that implies serious harm
      to yourself or others, or if a court of law requires me to disclose
      information.
    </p>
    <p>
      In the event that confidentiality must be broken I will always try to
      speak to you about this first, unless there are safeguarding issues that
      prevent this.
    </p>
    <p>
      Counsellors are required to have regular supervision support so I may
      discuss our work with my supervisor. This would be done without
      identifying you and my supervisor is a counsellor who also abides by the
      BACP&apos;s code of ethics regarding confidentiality.
    </p>
    <p>
      I keep brief paper or electronic notes of our therapy sessions for the
      purpose of assisting our work together. The notes help me to keep track of
      the issues that we are working on, and they are for my use only. The notes
      do not include any personal details that could be used to identify you and
      they are password protected (electronic ones) and stored securely in a
      locked filing box that only I have access to. Your therapy notes are
      stored separately to your personal details form.
    </p>
    <p>
      Once therapy has ended there are reasons why counsellors are required to
      keep records after therapy has ended. For example, in the case of
      financial transactions personal information must be retained for as long
      as legally required in respect of tax or accounting purposes. Please note
      that I need to keep a record of your name, date of birth and your client
      reference for seven years after therapy ends. Your therapy notes do not
      include any personal details that could be used to identify you and
      continue to be stored securely in a locked filing box that only I have
      access to. This time frame adheres with current industry guidelines. Seven
      years after therapy has ended your therapy notes will be confidentially
      destroyed.
    </p>
    <h2>Third party recipients of personal data</h2>
    <p>
      I share a limited amount of personal data with third parties in order to
      provide therapy services to you and to fulfil legal obligations in respect
      of tax and accounting purposes. For example, an accountant may need access
      to my invoices. If your appointments are paid for or arranged via a third
      party, for example, your employer, the only information shared with the
      third party is your dates of attendance and non-attendance for invoicing
      and payment purposes. Details about what is discussed in your appointments
      will remain confidential and can only be shared if you give me your
      written consent to do so.
    </p>
    <h2>Data security</h2>
    <p>
      I take the security of the data that I hold about you very seriously. My
      email account is password protected and mobile phones and laptops used to
      respond to your emails are password protected and have anti-virus
      software. Any email correspondence will be deleted within one month if it
      is not necessary to keep it. If it is necessary to retain the information,
      I will print the email and store it securely in a locked filing cabinet
      that only I have access to.
    </p>
    <h2>Website visitors</h2>
    <p>
      By accessing my website, you are consenting to the information collection
      and use practices described in this privacy notice. Should you choose to
      contact me using the contact form on the website none of the data that you
      supply will be stored by the website or passed to any third-party data
      processors. Instead, the data will be collated into an email and sent to
      me over the Simple Mail Transfer Protocol (SMTP). SMTP servers are
      protected by TLS (sometimes known as SSL) meaning that the email content
      is encrypted before being sent across the internet. The email content is
      then decrypted by local computers and devices.
    </p>
    <h2>Your data protection rights</h2>
    <p>
      Under GDPR, 2018 guidelines you have the following rights: The right to
      request access to the personal information that I store and process about
      you. You can ask for corrections to be made to the information held or for
      your personal information to be deleted. You can also ask me to restrict
      the processing of your personal information or to object to the processing
      of it altogether in some circumstances. You can read more about your
      rights at ico.org.uk/your-data-matters. If you would like to make a
      request relating to any of the rights above, please send a request in
      writing by emailing linda@ashcroftcounselling.co.uk Please be aware that
      in certain situations counsellors may be unable to comply with the above
      requests. For example, if compelled to retain the records by a court of
      law. Please also be aware that there may be a charge for complying with a
      request if it is deemed to be excessive in nature.
    </p>
    <h2>Queries</h2>
    <p>
      &apos;Data controller&apos; is the term used to describe the person or
      organisation that collects and stores and has responsibility for
      people&apos;s personal data. In this instance, the data controller is
      Linda Ashcroft and I am registered with the Information
      Commissioner&apos;s Office: the registration number for Linda Ashcroft
      Counselling is ZB541646 If you have any questions about this privacy
      policy or the way in which I handle your personal information you can
      contact me via phone on <a href="tel:07719968898">07719968898</a> or
      email:
      <a href="mailto:linda@ashcroftcounselling.co.uk">
        linda@ashcroftcounselling.co.uk
      </a>
    </p>
    <h2>How to complain</h2>
    <p>
      If you have any concerns about how I use of your personal information, you
      can make a complaint by emailing me at{' '}
      <a href="mailto:linda@counselling.co.uk">linda@counselling.co.uk</a>
    </p>
    <p>
      You can also complain to the ICO if you are unhappy with how I have used
      your data.
    </p>
    <p>
      The ICO&apos;s address:
      <br />
      Information Commissioner&apos;s Office
      <br />
      Wycliffe House
      <br />
      Water Lane
      <br />
      Wilmslow
      <br />
      Cheshire
      <br />
      SK9 5AF
      <br />
      Helpline number: <a href="0303 123 1113">0303 123 1113</a> ICO website:
      <a href="https://www.ico.org.uk" target="_blank" rel="noreferrer">
        https://www.ico.org.uk
      </a>
    </p>
    <h2>Changes to privacy notice.</h2>
    <p>
      This privacy notice may be updated from time to time, so please check
      occasionally for any updates.
    </p>
  </>
);

export default PolicyInfo;
