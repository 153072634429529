import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import About from '../pages/About/About';
import Contact from '../pages/Contact/Contact';
import FAQs from '../pages/FAQs/FAQs';
import Homepage from '../pages/Homepage/Homepage';
import MailSuccess from '../pages/MailSuccess/MailSuccess';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import ServicesAndFees from '../pages/ServicesAndFees/ServicesAndFees';

const RouteHandler = (): ReactElement => (
  <Routes>
    <>
      <Route path="/" element={<Homepage />} />
      <Route path="about" element={<About />} />
      <Route path="faq-and-contact" element={<Contact />} />
      <Route path="faqs" element={<FAQs />} />
      <Route path="contact-success" element={<MailSuccess />} />
      <Route path="services-and-fees" element={<ServicesAndFees />} />
      <Route path="*" element={<PageNotFound />} />
    </>
  </Routes>
);
export default RouteHandler;
