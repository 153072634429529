import React, { ReactElement } from 'react';
import { ThemeProvider } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import PageTitles from './constants/PageTitles';
import RouteHandler from './routes/Routes';
import muiTheme from './theme/MUITheme';

const App = (): ReactElement => (
  <HelmetProvider>
    <ThemeProvider theme={muiTheme}>
      <article>
        <Helmet>
          <title>{PageTitles.Homepage}</title>
          <meta
            name="description"
            content="Counselling by Linda Ashcroft, providing a safe and secure space in which you can explore your thoughts and feelings."
          />
          <meta
            name="keywords"
            content="wigan Wigan Warrington warrington st helens St Helens Saint Helens counsellor councellor therapy therapist help depressed unhappy struggling mental health services sessions online therapy anxiety treatment mental wellness manchester liverpool"
          />
        </Helmet>
        <RouteHandler />
      </article>
    </ThemeProvider>
  </HelmetProvider>
);
export default App;
