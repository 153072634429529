/* eslint-disable import/prefer-default-export */
import React, { ReactElement, useState } from 'react';
import { MobileNavContext } from './mobileNavContext';

interface MobileNavContextProviderProps {
  children: ReactElement;
}

export const MobileNavContextProvider = ({
  children,
}: MobileNavContextProviderProps): ReactElement => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [slideOut, setSlideOut] = useState(false);

  return (
    <MobileNavContext.Provider
      value={{
        mobileNavOpen,
        setMobileNavOpen,
        slideOut,
        setSlideOut,
      }}
    >
      {children}
    </MobileNavContext.Provider>
  );
};
