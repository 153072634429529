import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Zoom from '@mui/material/Zoom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { MainContent, Wrapper, StyledFab } from './styles';

interface PageWrapperProps {
  title: string;
  children: ReactElement;
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // for smoothly scrolling
  });
};

const PageWrapper = ({ title, children }: PageWrapperProps): ReactElement => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <MainContent>{children}</MainContent>
      <Zoom
        in={showButton}
        timeout={1000}
        style={{
          transitionDelay: `${showButton ? 500 : 0}ms`,
        }}
        unmountOnExit
      >
        <StyledFab
          onClick={scrollToTop}
          color="primary"
          aria-label="scroll to top"
        >
          <ArrowUpwardIcon />
        </StyledFab>
      </Zoom>
      <Footer />
    </Wrapper>
  );
};

export default PageWrapper;
