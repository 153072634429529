import React, { ReactElement } from 'react';
import { ExperienceContainer } from './styles';
import FlipBox from '../../../components/FlipBox/FlipBox';

const experience = [
  {
    title: 'Anxiety',
    description:
      'Anxiety can be a feeling of dread, fear or uneasiness. When anxiety feels intense or overwhelming it can start to interfere in our daily life.',
  },
  {
    title: 'Abuse',
    description:
      'Abuse is someone is causing us harm or distress that can be Physical, Emotional, Sexual, Domestic and Financial.',
  },
  {
    title: 'Anger',
    description:
      'Anger can be Short, Long, Hot or Cold. It is an intense emotion you feel when something has gone wrong or someone has wronged you.',
  },
  {
    title: 'Bereavement',
    description:
      'Bereavement is a normal response to a loss this can be a loved one, a pet, ajob, your independence a Miscarriage.',
  },
  {
    title: 'Depression',
    description:
      'Depression can be a feeling of hopeless &amp; helpless, having no motivation or interest in things we once enjoyed.',
  },
  {
    title: 'Low moods',
    description:
      "Low Mood is a common feeling after difficult events or major life's experiences, but sometimes has no obvious reasons.",
  },
  {
    title: 'Loneliness',
    description:
      "Loneliness can be that our need for social contact and relationship isn't being met",
  },
  {
    title: 'Low self-confidence',
    description:
      'Low self-confidence can be a poor overall sense of self-value.',
  },
  {
    title: 'Low self-esteem',
    description:
      'Low self-esteem can be experienced by having lack of confidence or self-doubt.',
  },
  {
    title: 'Relationship issues',
    description:
      'Relationship issues are problems that affect the quality and satisfaction of a relationship. This can be a partner or family relative.',
  },
  {
    title: 'Work related problems',
    description:
      'Work related issues can be, Interpersonal conflict, Communication problems, Gossip, Bullying, Low motivation and job satisfaction.',
  },
];

const Experience = (): ReactElement => (
  <>
    <h2>Here are some of the counselling areas I have experience in. </h2>
    <ExperienceContainer>
      {experience.map(({ title, description }) => (
        <FlipBox key={title} title={title} description={description} />
      ))}
    </ExperienceContainer>
  </>
);
export default Experience;
