import React, { ReactElement } from 'react';

const CPD = (): ReactElement => (
  <>
    <h2>Continual professional development</h2>
    <p>
      I am committed to improving my knowledge and skills and I have gained the
      following: Working with Anger, Safeguarding, Bereavement Awareness, Grief
      & Bereavement, and Introduction to Anxiety Disorders, Working with
      Personality Disorders, Understanding and managing loneliness, Basic First
      aid awareness.
    </p>
  </>
);
export default CPD;
