import styled from 'styled-components';

export const InnerWrapper = styled.section`
  width: 100%;
`;

export const TextWrapper = styled.section`
  width: 96%;
  padding: 0 2%;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.8em;
    text-align: center;
  }
  h2 {
    font-size: 1.75em;
    text-align: center;
  }
  p {
    font-size: 1.5em;
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: 1.2em;
    }
    p {
      font-size: 1em;
    }
  }
`;

export const SubTextWrapper = styled(TextWrapper)`
  background: #382336;
  color: #ffffff;
`;

export const ButtonWrapper = styled.section`
  display: flex;
  justify-content: center;
`;
