import React, { ReactElement } from 'react';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import SchoolIcon from '@mui/icons-material/School';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Link } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import FaceToFaceImg from '../../assets/Face-to-face.jpg';
import OnlineSessionImg from '../../assets/Online-session.jpg';
import PhoneSessionImg from '../../assets/Phone-session.jpg';
import {
  AvailabilityTitle,
  Concession,
  ConcessionsContainer,
  FreeMsg,
  OpeningSpan,
  OpeningTime,
  OpeningTimesContainer,
  Service,
  ServicesContainer,
  ServicesTitle,
} from './styles';

const services = [
  {
    title: 'Face to face',
    img: FaceToFaceImg,
    desc: (
      <>
        <p>I am currently renting a room to provide this service.</p>
        <p>The room is very private and parking is available. </p>
        <p>Session will last for 60 minutes.</p>
      </>
    ),
    price: '£45.00',
  },
  {
    title: 'Online Counselling',
    img: OnlineSessionImg,
    desc: (
      <>
        <p>Counselling will take place over a secure platform.</p>
        <p>I will forward the links prior to the session.</p>
        <p>
          This is ideal for anyone who feels more comfortable in their own
          space.
        </p>
      </>
    ),
    price: '£45.00',
  },
  {
    title: 'Phone Counselling',
    img: PhoneSessionImg,
    desc: (
      <>
        <p>Counselling will take place over a phone call.</p>
        <p>
          This is ideal for anyone who feels more comfortable in their own
          space.
        </p>
      </>
    ),
    price: '£45.00',
  },
];

const concessions = [
  {
    icon: <CurrencyPoundIcon />,
    wording: 'Low Income',
  },
  {
    icon: <SchoolIcon />,
    wording: 'Students',
  },
  {
    icon: <LocalLibraryIcon />,
    wording: 'Trainee Counsellors',
  },
];

const openingTimes = [
  {
    day: 'Monday',
    text: '9am - 5pm',
  },
  {
    day: 'Tuesday',
    text: '9am - 7pm',
  },
  {
    day: 'Wednesday',
    text: '9am - 5pm',
  },
  {
    day: 'Thursday',
    text: '9am - 7pm',
  },
  {
    day: 'Friday',
    text: '9am - 4pm',
  },
];

const ServicesAndFees = (): ReactElement => (
  <PageWrapper title={PageTitles.ServicesAndFees}>
    <>
      <ServicesTitle>Services And Fees</ServicesTitle>
      <ServicesContainer>
        {services.map((service) => (
          <Service backgroundImg={service.img}>
            <h2>{service.title}</h2>
            <>{service.desc}</>
            <p>Fee - {service.price} per session</p>
          </Service>
        ))}
      </ServicesContainer>
      <h1>Concessions offered for</h1>
      <ConcessionsContainer>
        {concessions.map((concession) => (
          <Concession>
            {concession.icon}
            <h2>{concession.wording}</h2>
          </Concession>
        ))}
      </ConcessionsContainer>
      <FreeMsg>
        <p>I do offer a FREE no obligation 20 minute consultation.</p>
        <p>
          This is where you can ask any questions, please feel free to contact
          me to arrange.
        </p>
        <p>EAP &amp; G.P. and other professional referrals welcome.</p>
      </FreeMsg>
      <AvailabilityTitle>Availability</AvailabilityTitle>
      <OpeningTimesContainer>
        {openingTimes.map((opening) => (
          <OpeningTime>
            <CalendarTodayIcon />
            <h2>{opening.day}</h2>
            <p>{opening.text}</p>
          </OpeningTime>
        ))}
      </OpeningTimesContainer>
      <OpeningSpan>
        Please <Link to="/faq-and-contact">contact</Link> for further details
      </OpeningSpan>
    </>
  </PageWrapper>
);
export default ServicesAndFees;
