import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';

const PageNotFound = (): ReactElement => (
  <PageWrapper title={PageTitles.PageNotfound}>
    <>
      <h1>404 - Page Not Found</h1>
      <Button type="link" variant="contained" href="/">
        Go back home
      </Button>
    </>
  </PageWrapper>
);

export default PageNotFound;
