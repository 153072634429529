import React, { ReactElement } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import { InnerWrapper, TextWrapper, SubTextWrapper } from '../Homepage/styles';
import CPD from './CPD/CPD';
import Experience from './Experience/Experience';
import Qualifications from './Qualifications/Qualifications';
import Linda from '../../assets/Linda.jpg';
import { AboutIntroSection, LindaImg } from './styles';

const About = (): ReactElement => (
  <PageWrapper title={PageTitles.About}>
    <InnerWrapper>
      <TextWrapper>
        <h1>About Me</h1>
        <AboutIntroSection>
          <LindaImg src={Linda} alt="Linda Ashcroft" />
          <div>
            <p>
              Hello and welcome to my website, my name is Linda and I am a fully
              qualified and experienced person-centred counsellor. This
              originated and was developed by Carl R Rogers an American
              Psychologist.
            </p>
            <p>
              I do believe that we are all unique and have our own ways in how
              we deal with issues that arise in our lives and you are the expert
              on you. It is my role within the therapy in helping you to gain a
              better understanding of self, feel more empowered and resourceful
              and gain more control over your life.
            </p>
            <p>
              Being a person-centred counsellor means I offer a safe,
              non-judgemental environment for you to explore your thoughts and
              feelings. You may not be able to change the issue but I do believe
              that the answer lies within you in how best to gain acceptance and
              be able to move forward in life.
            </p>
          </div>
        </AboutIntroSection>
      </TextWrapper>
      <SubTextWrapper>
        <Qualifications />
      </SubTextWrapper>
      <TextWrapper>
        <CPD />
      </TextWrapper>
      <SubTextWrapper>
        <Experience />
      </SubTextWrapper>
    </InnerWrapper>
  </PageWrapper>
);
export default About;
